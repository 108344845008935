.jockContainer {
  text-align: left;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.sub-header {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;
}

.main-header {
  font-size: 42px;
  margin-bottom: 30px;
  font-weight: 800;
  padding: 0% 20% 0% 20%;
  font-weight: 600;
  font-family: "Kanit", sans-serif;
  line-height: 46px;
}

.icon-container {
  padding: 40px;
  margin: 20px;
}

.letter-container {
  display: flex;
  padding: 0% 30% 0% 30%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  color: #cccccc;
}

.letter {
  margin: 10px;
}

p {
  margin: 10px;
  font-size: 14px;
}

.icon {
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.transferFunds-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  height: 20px;
  padding: 25px 180px 25px 180px;
  margin-top: 80px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  border: 0px;
}

.transferFunds-button:hover {
  cursor: pointer;
}

.transferFundsLink {
  text-decoration-line: none;
  font-size: 14px;
  color: #cccccc;
  margin-bottom: 40px;
}

.transferBanner {
  margin-bottom: 100px;
  margin-top: 25px;
}

.sign-img {
  margin-left: 10px;
}

.link-page {
  text-decoration: underline;
  color: #cccccc;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .main-header {
    padding: 10px 30px 20px 30px;
    font-size: 24px;
    line-height: 24px;
  }

  .sub-header {
    margin-bottom: 0px;
  }

  .letter-container {
    padding: 0% 5% 0% 5%;
  }

  .icon-container {
    padding: 0px 10px 0px 10px;
  }

  .icon {
    width: 263px;
  }

  .transferFunds-button {
    padding: 25px 80px 25px 80px;
  }
}

@media (max-width: 390px) {
  .main-header {
    padding: 10px 20px 20px 20px;
    font-size: 24px;
    line-height: 24px;
  }

  .letter-container {
    padding: 0% 5% 0% 5%;
  }

  .transferFunds-button {
    padding: 25px 60px 25px 60px;
  }

  .icon {
    width: 263px;
  }

  .icon-container {
    padding: 0px 10px 0px 10px;
  }
}

@media (width: 540px) {
  .main-header {
    padding: 10px 30px 30px 30px;
    font-size: 24px;
    line-height: 24px;
  }

  .letter-container {
    padding: 0% 10% 0% 10%;
  }

  .transferFunds-button {
    padding: 25px 100px 25px 100px;
  }

  .icon {
    width: 263px;
  }

  .icon-container {
    padding: 0px 10px 0px 10px;
  }
}

@media (max-width: 350px) {
  .main-header {
    font-size: 24px;
    line-height: 24px;
    padding: 10px 20px 20px 20px;
  }

  .letter-container {
    padding: 0% 5% 0% 5%;
  }

  .transferFunds-button {
    padding: 25px 50px 25px 50px;
  }

  .icon {
    width: 263px;
  }

  .icon-container {
    padding: 0px 10px 0px 10px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .letter-container {
    padding: 0% 20% 0% 20%;
  }
}
