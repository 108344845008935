.faqContainer {
    display:flex-start;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 20px 30%;
    color: #fff; 
  }
  
  .faq-title{
    display: flex start;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .faqContainer .accordion-item {
    border-top: 1px solid #333;
  }
  
  .faqContainer .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #cccccc; 
  }
  
  .faqContainer .accordion-header:hover {
    color: #ffffff; 
  }
  
  .faqContainer .accordion-header::after {
    content: '+';
    font-size: 16px;
    color: #cccccc; 
    cursor: pointer;
  }
  
  .faqContainer .accordion-header[aria-expanded='true']::after {
    content: '-'; 
    cursor: pointer;
  }
  
  .faqContainer .accordion-body {
    padding: 10px 0;
    font-size: 16px;
    color: #cccccc; 
    font-weight: 400;
  }
  
  .faqContainer .accordion-body p {
    margin: 5;
  }

  @media (max-width: 1024px) {
    .faqContainer {
        padding: 0% 10% 0% 10%;
        margin-top: 60px;
    }
  }
