.splashContainer {
  text-align: center;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.header-container2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.peers {
  color: #4bebe2;
}
.splashTagline {
  font-size: 42px;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Kanit", sans-serif;
  line-height: 46px;
  padding: 0% 20% 0% 20%;
}

.p1 {
  color: #cccccc;
  font-size: 16px;
  padding: 0px;
  margin-bottom: 40px;
}

.p2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  margin-top: 70px;
}

.p3 {
  color: #cccccc;
  font-size: 16px;
  font-weight: 400;
  padding: 0% 32% 0% 32%;
}

.header2 {
  font-size: 24px;
  margin-top: 80px;
  font-weight: 800;
}

.mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  border-radius: 10px;
}

.mobileLogo {
  height: 20%;
  width: 80%;
  border-radius: 10px;
}

.frames {
  padding: 20px;
}

.frame {
  padding: 5px;
}

.masters {
  display: flex;
  font-size: 10px;
  margin-left: 83%;
  margin-top: -2px;
}

h2 {
  font-family: "Kanit", sans-serif;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
}

.buttonTagline {
  padding: 40px;
  color: #cccccc;
  font-size: 14px;
  padding: 0% 20% 0% 20%;
  font-size: 14px;
}

.transferAccounts-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0px;
  background-color: #4bebe2;
  color: #000000;
  padding: 10px 200px 10px 200px;
  margin: 20px;
  margin-top: 100px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.transferAccountsLink {
  text-decoration-line: none;
}

.learn {
  text-decoration: underline;
  color: #cccccc;
  font-size: 14px;
}

@media (max-width: 600px) {
  .splashTagline {
    padding: 10px 20px 20px 20px;
    font-size: 24px;
    line-height: 26px;
  }

  .splashIcon {
    width: 160px;
  }

  .icon-container2 {
    padding: 0px 10px 0px 10px;
  }

  .mobile-container {
    padding: 10px;
  }

  .mobileLogo {
    width: 100%;
  }

  .p3 {
    padding: 0% 5% 0% 5%;
  }

  .buttonTagline {
    padding: 0% 8% 0% 8%;
  }

  .frames {
    display: block;
    padding: 10px;
  }

  .map {
    width: 100%;
  }

  .transferAccounts-button {
    padding: 15px 60px 15px 60px;
  }

  .masters {
    margin-left: 55%;
    margin-bottom: 10px;
  }
}

@media (max-width: 390px) {
  .masters {
    margin-left: 37%;
    margin-bottom: 10px;
  }

  .transferAccounts-button {
    padding: 15px 50px 15px 50px;
  }
}

@media (width: 540px) {
  .masters {
    margin-left: 41%;
  }
}

@media (max-width: 350px) {
  .masters {
    margin-left: 120px;
    margin-bottom: 10px;
  }

  .transferAccounts-button {
    padding: 15px 30px 15px 30px;
  }
}
