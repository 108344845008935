.splashContainer3 {
  text-align: center;
  background-color: #000000;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.header {
  font-size: 42px;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Kanit", sans-serif;
  line-height: 46px;
  padding: 0% 20% 0% 20%;
}

.G2G {
  color: #4bebe2;
}

.c1 {
  color: #cccccc;
  margin-bottom: 0px;
  font-size: 16px;
  align-content: left;
  display: flex start;
  padding: 2% 9% 0% 9%;
}

.icon-container3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

.splashConfetti {
  height: 400px; /* Adjust height as needed */
  width: auto;
}

@media (max-width: 600px) {
  .header {
    padding: 10px 20px 20px 20px;
    font-size: 24px;
    line-height: 26px;
  }

  .splashConfetti {
    width: 100%;
    height: auto;
    padding: 10% 5% 20% 5%;
    margin-bottom: 15%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .header {
    padding: 5% 20% 5% 20%;
  }

  .splashConfetti {
    margin-top: 50px;
    margin-bottom: 20%;
    width: 100%;
    height: auto;
    padding-bottom: 100px;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .header {
    padding: 5% 20% 5% 20%;
  }

  .splashConfetti {
    margin-top: 160px;
    margin-bottom: 15%;
    width: 100%;
    height: auto;
    padding-bottom: 100px;
  }
}
