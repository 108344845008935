.App {
  text-align: left;
  background-color: #000000;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

