.footer-section1 {
  color: #ffffff;
  background-color: #131416;
  display: flex;
  width: 100%;
  height: max-content;
}

.legalContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  font-weight: 800;
  padding: 50px;
}

a {
  color: #95999d;
  text-decoration: none;
}

ul {
  list-style: none;
  align-items: left;
  margin-left: -35px;
  margin-top: 20px;
}

ul li {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 390px) {
    .legalContainer {
        margin-left: 0px;
    }
}

@media (max-width: 500px) {
  .legalContainer {
    margin-left: -20px;
  }
}
